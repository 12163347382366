import timedFetch from 'middleware/timedFetch';
import type { Cookies } from 'types/cookies';

export type SponsoredAdsBannerAdsPageType = 'Homepage' | 'SearchResults' | 'PDP' | 'Category';

export interface SponsoredAdsBannerAdsRequestBody {
  url: string;
  pageType: SponsoredAdsBannerAdsPageType;
  clientType: 'mobile' | 'desktop' | 'tablet';
  width: number;
  height: number;
  /** only 1 min result is supported at the moment from the ads endpoint */
  minimum: number;
  /** only 1 max result is supported at the moment from the ads endpoint */
  maximum: number;
  /** only used on search */
  matchStyles?: string[];
  /** only used on search top ad */
  searchTerm?: string;
  brands?: string[];
}

export interface SponsoredAdsBannerAd {
  impressionTracker: string;
  clickTracker: string;
  viewTracker: string;
  campaignId: number;
  assetUrl: string;
  clickThru: string;
  brand: string | null;
}

export interface SponsoredAdsBannerAdsResponse {
  requestId: string;
  bannerAds: SponsoredAdsBannerAd[];
}

export function getSponsoredAdsBannerAds({
  cookies,
  body,
  sponsoredAdsUrl,
  controller,
  fetcher = timedFetch('getSponsoredAdsBannerAds')
}: {
  cookies?: Cookies;
  body: SponsoredAdsBannerAdsRequestBody;
  sponsoredAdsUrl: string;
  controller: AbortController;
  fetcher?: Function;
}): Promise<Response<SponsoredAdsBannerAdsResponse>> {
  const cookie = cookies ? `session-id=${cookies['session-id']};zfc=${cookies.zfc}` : '';
  const opts = {
    method: 'post',
    credentials: 'include', // client
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*',
      cookie // server
    },
    body: JSON.stringify(body),
    signal: controller.signal
  };
  return fetcher(`${sponsoredAdsUrl}/sponsored-ads/bannerAds`, opts);
}
