import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { triggerAssignment } from 'actions/ab';
import { isValidTestTreatment } from 'helpers/SymphonyHydraTestValidator';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
// Components
// use loadable components here.  The server side rendering and
// webpackPrefetch should make it so that the components we need on initial load
// load in the foreground and then we prefetch the rest in the background.
// in theory, this should speed up the initial js download with little to no overhead.
const Ad = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/Ad'));
const Agreement = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/Agreement'));
const BrandReviews = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/BrandReviews'));
const BrandTrending = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/BrandTrending'));
const BrokenPage = loadable(() => import(/* webpackPrefetch: true */ 'components/error/BrokenPage'));
const ChromeAbandonedCartHeader = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ChromeAbandonedCartHeader'));
const Departments = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/Departments'));
const EGiftCard = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/EGiftCard'));
const EventCallout = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/EventCallout'));
const Faqs = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/Faqs'));
const GenericBrandFacets = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/GenericBrandFacets'));
const Iframe = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/Iframe'));
const Images = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/Images'));
const MelodyArticleImageCopy = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyArticleImageCopy'));
const MelodyArticleImages = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyArticleImages'));
const MelodyBrandIndex = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyBrandIndex'));
const MelodyCategory = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyCategory'));
const MelodyEditorialPromo = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyEditorialPromo'));
const MelodyHeaderRule = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyHeaderRule'));
const MelodyHero = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyHero'));
const MelodyHeroFull = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyHeroFull'));
const MelodyHorizontalNav = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyHorizontalNav'));
const MelodyNewsfeed = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/newsfeed/MelodyNewsfeed'));
const MelodyPersonalizedBrand = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyPersonalizedBrand'));
const MelodyPersonalizedCategories = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyPersonalizedCategories'));
const MelodyPromoGroup = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyPromoGroup'));
const MelodySizingGuide = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodySizingGuide'));
const MelodySplitEditorial = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodySplitEditorial'));
const MelodyVerticalNav = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/MelodyVerticalNav'));
const MelodyVideoPlayer = loadable(() => import(/* webpackPrefetch: true */ 'components/common/melodyVideo/MelodyVideoPlayer'));
const NotificationSignup = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/NotificationSignup'));
const PageContent = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/PageContent'));
const ProductSearch = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ProductSearch'));
const Recommender = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/Recommender'));
const ReleaseCalendar = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ReleaseCalendar'));
const ShopTheLook = loadable(() => import(/* webpackPrefetch: true */ 'containers/landing/ShopTheLook'));
const VipDashboardHeader = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/VipDashboardHeader'));
const VipOptIn = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/VipOptIn'));
const VipPrimeLink = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/VipPrimeLink'));
const ZapposForm = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZapposForm'));
const ZapposHero = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZapposHero'));
const ZapposPromoGroup = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZapposPromoGroup'));
const ZapposQuickSubscription = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZapposQuickSubscription'));
const ZAWAccordion = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWAccordion'));
const ZAWCompanyCallout = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWCompanyCallout'));
const ZAWConfirmationCallout = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWConfirmationCallout'));
const ZAWForm = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWForm'));
const ZAWFormStandalone = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWFormStandalone'));
const ZAWImageAndCallout = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWImageAndCallout'));
const ZAWTaskList = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWTaskList'));
const ZAWTestimonials = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWTestimonials'));
const ZAWTextCallout = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWTextCallout'));
const ZAWVideoPlayer = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWVideoPlayer'));
const ZAWModernForm = loadable(() => import(/* webpackPrefetch: true */ 'components/landing/ZAW/ZAWModernForm'));
const SurveyForm = loadable(() => import(/* webpackPrefetch: true */ 'components/survey/SurveyForm'));
const VideoPlayerWrapper = loadable(() => import(/* webpackPrefetch: true */ 'components/common/VideoPlayer/VideoPlayerWrapper'));
import { BannerAdSlot } from 'components/common/BannerAd/BannerAdSlot';

export const SOREL_FORM = 'SorelForm';

const slotContentLoaded = {};

/**
 * ensure that the named component is loaded
 * return {loaded: true} if the component is
 * otherwise return {loaded: false, promise}
 * `promise
 */
export function ensureLoaded(name) {
  if (!slotContentLoaded[name]) {
    if (typeof slotContentTypes?.[name]?.load === 'function') {
      slotContentLoaded[name] = {
        loaded: false,
        promise: slotContentTypes[name].load()
      };
      slotContentLoaded[name].promise.then(() => {
        slotContentLoaded[name] = { loaded: true };
      });
    } else {
      // if we can't load it, just assume it's loaded
      slotContentLoaded[name] = { loaded: true };
    }
  }
  return slotContentLoaded[name];
}

// The keys here map directly to the component name in content symphony.
const slotContentTypes = {
  ad: Ad,
  agreement: Agreement,
  bannerAd: BannerAdSlot,
  brandNotification: NotificationSignup,
  BrokenPage: BrokenPage,
  chromeAbandonedCartHeader: ChromeAbandonedCartHeader,
  departments: Departments,
  egiftcard: EGiftCard,
  eventCallout: EventCallout,
  faqs: Faqs,
  genericBrandAbout: PageContent, // reusing PageContent for Taxonomy pages.
  genericBrandEmails: NotificationSignup, // reusing Notifications for Taxonomy pages
  genericBrandFacets: GenericBrandFacets,
  genericBrandReviews: BrandReviews,
  genericBrandTrending: BrandTrending,
  iframe: Iframe,
  images: Images,
  melodyArticleImageCopy: MelodyArticleImageCopy,
  melodyArticleImages: MelodyArticleImages,
  melodyBrandIndex: MelodyBrandIndex,
  melodyCategory: MelodyCategory,
  melodyEditorialPromo: MelodyEditorialPromo,
  melodyHeaderRule: MelodyHeaderRule,
  melodyHero: MelodyHero,
  melodyHeroFull: MelodyHeroFull,
  melodyHorizontalNav: MelodyHorizontalNav,
  melodyNewsFeed: MelodyNewsfeed,
  melodyPersonalizedBrand: MelodyPersonalizedBrand,
  melodyPersonalizedCategories: MelodyPersonalizedCategories,
  melodyPromoGroup: MelodyPromoGroup,
  melodySizingGuide: MelodySizingGuide,
  melodySplitEditorial: MelodySplitEditorial,
  melodyVerticalNav: MelodyVerticalNav,
  melodyVideoPlayer: MelodyVideoPlayer,
  pageContent: PageContent,
  productSearch: ProductSearch,
  recommender: Recommender,
  releaseCalendar: ReleaseCalendar,
  shopTheLook: ShopTheLook,
  [SOREL_FORM]: SurveyForm,
  VideoPlayer: VideoPlayerWrapper,
  vipDashboardHeader: VipDashboardHeader,
  vipOptIn: VipOptIn,
  vipPrimeLink: VipPrimeLink,
  ZapposForm: ZapposForm,
  zapposHero: ZapposHero,
  zapposPromoGroup: ZapposPromoGroup,
  ZapposQuickSubscription: ZapposQuickSubscription,
  ZAWAccordion: ZAWAccordion,
  ZAWCompanyCallout: ZAWCompanyCallout,
  ZAWConfirmationCallout: ZAWConfirmationCallout,
  ZAWForm: ZAWForm,
  ZAWFormStandalone: ZAWFormStandalone,
  ZAWImageAndCallout: ZAWImageAndCallout,
  ZAWTaskList: ZAWTaskList,
  ZAWTestimonials: ZAWTestimonials,
  ZAWTextCallout: ZAWTextCallout,
  ZAWVideoPlayer: ZAWVideoPlayer,
  ZAWModernForm: ZAWModernForm
};

const renderSlot = (testTreatment, assignmentGroup, testName) => {
  if (assignmentGroup !== null) {
    return testTreatment[assignmentGroup] === 'Render';
  }
  return !testName;
};

export const LandingSlot = props => {
  const {
    isRecognized,
    triggerAssignment,
    hasAssignmentTriggered,
    pageName,
    pageInfo,
    slotName,
    data = {},
    onTaxonomyComponentClick,
    onComponentClick,
    shouldLazyLoad,
    slotHeartsData,
    ipStatus,
    slotContentTypesList = slotContentTypes,
    slotIndex,
    isFullWidth,
    slideWidths
  } = props;

  const { componentName, testName, testTreatment, testTrigger } = data;

  /*
   * Components can be reassigned through the Symphony testTreatment
   * attribute. Setting up initialized variables for reassignment
   * if needed.
   */
  let componentContent = data;
  let componentContentName = componentContent.componentName;

  /*
   * FUTURE:
   * Hook definition to set up test assignment for a Symphony component.
   * Evaluates the test attributes and trigger criteria before
   * assigning user to test. setShouldShow to true at this point
   * to avoid showing initial component, then swapping it on the next
   * tick with the treatment or fallback component.
   */
  useEffect(() => {
    const validTest = isValidTestTreatment({
      testName,
      testTrigger,
      testTreatment,
      isRecognized,
      hasAssignmentTriggered
    });

    if (validTest) {
      const { index = 0 } = triggerAssignment(testName) || {};
      setAssignmentGroup(index);
    }
    setShouldShow(true);
  }, [hasAssignmentTriggered, isRecognized, testName, testTreatment, testTrigger, triggerAssignment]);

  const [assignmentGroup, setAssignmentGroup] = useState(null);
  const shouldRenderSlot = renderSlot(testTreatment, assignmentGroup, testName);
  const [shouldShow, setShouldShow] = useState(shouldRenderSlot);
  if (!componentName) {
    return null;
  }

  /*
   * Checking for a testTreatment. If the assignmentGroup aligns
   * with a key containing a component object, overwrite the component data.
   * Else if there is no assignment, but there is a fallback component object,
   * overwrite with that. If there is a testName and no testTreatment assignment
   * or the assigment explicitly says not to render, set the component name to null.
   */
  if (testName && testTrigger && testTreatment) {
    const { fallback } = testTreatment;
    if (testTreatment[assignmentGroup] instanceof Object) {
      componentContent = testTreatment[assignmentGroup];
      componentContentName = testTreatment[assignmentGroup].componentName;
    } else if (!hasAssignmentTriggered && fallback instanceof Object) {
      componentContent = fallback;
      componentContentName = fallback.componentName;
    } else if ((!testTreatment[assignmentGroup] && !fallback) || testTreatment[assignmentGroup] === 'DoNotRender') {
      componentContentName = null;
    }
  }
  const SlotContent = slotContentTypesList[componentContentName];

  return SlotContent && (shouldRenderSlot || shouldShow) ? (
    <SlotContent
      slotName={slotName}
      slotDetails={componentContent}
      slotIndex={slotIndex}
      pageName={pageName}
      pageInfo={pageInfo}
      onComponentClick={onComponentClick}
      onTaxonomyComponentClick={onTaxonomyComponentClick}
      shouldLazyLoad={shouldLazyLoad}
      slotHeartsData={slotHeartsData}
      ipStatus={ipStatus}
      isFullWidth={isFullWidth}
      slideWidths={slideWidths}
    />
  ) : null;
};

const mapStateToProps = (state, ownProps) => ({
  isRecognized: !!state.cookies['x-main'],
  hasAssignmentTriggered: Object.keys(state.ab.assignments).includes(ownProps.data?.testName)
});

const mapDispatchToProps = {
  triggerAssignment
};

const ConnectedLandingSlot = connect(mapStateToProps, mapDispatchToProps)(LandingSlot);
export default withErrorBoundary('LandingSlot', ConnectedLandingSlot);
